
import React, { Component } from "react";

import music from "../images/music.png"

import muz from "../video/ves.mp3"
import img2 from "../images/love.png"

export default class Blog extends Component {
  render() {
    return (

      <div className="container blog-container mt-4">
        <div className="row main-row ">
          <div className="col-lg-4 col-md-12 col-sm-12">
            <div className="blog-img text-center">
              <img className="img-fluid" src={this.props.img} />
            </div>
          </div>
          <div className="col-lg-8 col-md-12 col-sm-12">
            <div className="blog-title mt-lg-5 mt-md-2 mt-sm-1">
       
              <h5 className="text-center">
                {this.props.title1}
              </h5>
              <h2 className="text-center p-2">
                {this.props.title2}
              </h2>
              <h4 className="text-center">
                {this.props.message} <br/><br/>
               <img className="music animate__animated animate__pulse animate__delay-4s animate__infinite" style={{width: '150px'}} src={img2}/>
              </h4>
             
              <p className="text-center pt-2 pb-5">
              PS. Vă vom fi recunoscători dăcă tradiționalul buchet de flori va fi înlocuit cu o carte sau cu o sticlă de vin în semn de recunoștință.
<br/>Nu vă uitați acasă bună dispoziție și încălțămintea de dans!
              </p>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

