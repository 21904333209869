import restaurant from "./images/costesti.jpeg";
import img_card from "./images/n01.jpg";
import imgheaderrestaurant from "./images/14.jpg";
import imgheader from "./images/img6.jpg";
import imgheaderm from "./images/img6.jpg";
import img2 from "./images/img2.jpg";
import imgheadermiini from "./images/img4.jpg";
import imgheadermiinimb from "./images/img4.jpg";
import icon from "./images/porumbel.png";


const data = {
    introData: [{
        title: "Invitatie Online",
        mire: "Alexandru",
        mireasa: "Valeria",
        data: "13 Iulie 2024",
        data_confirmare: "20 iunie 2024",
        savedata: "~ Salvează Data ~",
        imgdesktop: imgheader,
        imgmobil: imgheaderm,
        email: "alleria.butnaru@gmail.com", 
       tel: "+37360082811",
       phone: "tel:+37360082811",
       viber: "viber://chat?number=%2B37360082811",
       whatsapp: "https://wa.me/+37360082811",
       messenger: "https://www.messenger.com/t/valeria.s.sweet",
       tel1: "+37360082811",
       phone1: "tel:+XXX",
       viber1: "viber://chat?number=%2BXX",
       whatsapp1: "https://wa.me/+XXX",
       messenger1: "https://www.messenger.com/t/100008466997487",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "Locația Evenimentului",
            name: "Complexul Turistic Costești",
            name1: "(sala Panoramică)",
            data: "13 iulie 2024, sâmbătă, ora 17:00",
            adress: "Costești, r-l. Ialoveni",
            harta: "https://maps.app.goo.gl/H24XzDxFLXyXz3aZA",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10907.758582066008!2d28.7527063!3d46.8842359!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c983dd03faec09%3A0x61edd29ee6261aa2!2z0KLRg9GA0LjRgdGC0LjRh9C10YHQutC40Lkg0JrQvtC80L_Qu9C10LrRgSAi0JrQvtGB0YLQtdGI0YLRjCI!5e0!3m2!1sro!2s!4v1707234575640!5m2!1sro!2s" 
        }

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Ne căsătorim!",
            title2: "VĂ INVITĂM LA NUNTA NOASTRĂ!",
            message: "În ziua în care vom celebra căsătoria nostră, vrem să fim inconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a vă invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img2,
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            img: imgheaderrestaurant,
            img2: icon,
            title: "",
            message: "",
            message1: "CU BINECUVÂNTAREA PĂRINȚILOR",
            parintii_nume1: "Vitalie și Suzana",
            parintii_nume2: "Valeriu și Angela",
            parintii_nume3: "",
            parintii_nume4: "",
            nasii: "ȘI SUSȚINEREA NAȘILOR",
            nasii_nume: "Gheorghe & Vera",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "De aici începe totul.",
            message: "\"Dragostea constă în dorința de a da ceea ce este al tău altuia și de a simți fericirea acestuia ca și cum ar fi a ta. \" – Emanuel Swdenborg",
        }
    ],

}

export default data;